<template>
  <section class="bg_color">
    <h2>あの人の登録モーター</h2>

    <div class="inner radius">
      <div class="box_user_name nominate">
        <div class="user_inner">
          <figure class="thum">
            <img
              :src="royalUser.icon ? royalUser.icon : Icon[0].img"
              width=""
              height=""
              alt=""
            />
          </figure>
          <h3>
            <p class="user_name">{{ royalUser.nickname }}</p>
          </h3>
        </div>
      </div>
      <div class="box_get_mog">
        <div class="ribon_outline none">
          <div class="ttl_ribon nominate">
            <p class="ribon_inner">GUEST OWNED MOTOR</p>
          </div>
        </div>
        <h4>
          <span class="name">{{ royalUser.nickname }}</span
          >の登録した<br class="visible_sp" />モーターが獲得したモグー総数
        </h4>
        <p class="ttl_main">
          <span class="mog">{{ royalUser.current_point }}</span>
        </p>
        <div class="data">
          <p class="date"><Today /></p>
          <p class="event"><LastRace /></p>
        </div>
      </div>

      <div class="box_lst nominate">
        <h4 class="ttl_lst" v-if="pickupResult.length > 0">
          <p class="inner">
            <span class="name">{{ royalUser.nickname }}</span
            >の<br class="visible_sp" />"イチ推し"モーター
          </p>
        </h4>

        <!-- <div class="outline_nominate_current" v-if="pickupResult.length > 0"> -->
        <div class="outline_nominate_current">
          <div class="box_nominate_current">
            <p v-html="formatNewlines(royalUser.comment)"></p>
            <!-- <div class="box_thum">
              <figure class="round">
                <img
                  src="/images/parts/thum_moter01.jpg"
                  width=""
                  height=""
                  alt=""
                />
              </figure>
              <p class="name_large">
                <span class="small">モーターNo.</span
                >{{ Number(pickupResult[0].mno) }}
              </p>
            </div>
            <div class="dtl_inner">
              <div class="data">
                <div class="date">
                  <p class="ttl">登録日</p>
                  <p class="txt">
                    {{ pickupResult[0].selected_date }}
                  </p>
                </div>
                <div class="point">
                  <p class="ttl">獲得モグー</p>
                  <p class="txt">
                    <span class="mog">{{ pickupResult[0].current_mog }}</span>
                  </p>
                </div>
              </div>
              <p class="read">
                {{ pickupResult[0].comment }}
              </p>
              <div>
                <router-link
                  :to="{
                    name: 'MotorDtl',
                    params: { id: pickupResult[0].mno },
                  }"
                  class="btn_dtl"
                  >モーター履歴を見る</router-link
                >
              </div>
            </div> -->
          </div>
        </div>

        <div class="nominate_slide">
          <Splide class="lst d-block-sp" :options="options">
            <!-- <SplideSlide v-for="val in motorResult" :key="val"> -->
            <SplideSlide v-for="val in selectMotor" :key="val">
              <div class="block-nominate_dtl">
                <div class="box_thum">
                  <figure class="round">
                    <img :src="motorImg[val.mno]" alt="" />
                  </figure>
                  <p class="name_large">
                    <span class="small">モーターNo.</span>{{ Number(val.mno) }}
                  </p>
                </div>
                <div class="box_dtl">
                  <p class="ttl none">登録日</p>
                  <p class="txt">
                    {{ val.selected_date }}
                  </p>
                </div>
                <div class="box_dtl">
                  <p class="ttl none">獲得モグー</p>
                  <p class="txt point">{{ val.current_mog }}</p>
                </div>
                <!-- <div class="box_dtl">
                  <div class="read">
                    {{ val.comment }}
                  </div>
                </div> -->
                <div class="box_dtl">
                  <router-link
                    :to="{ name: 'MotorDtl', params: { id: val.mno } }"
                    class="btn_dtl"
                    >モーター履歴を見る</router-link
                  >
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>

      <section class="box_nominate_other">
        <h4 class="ttl_main">他の人の登録モーター</h4>
        <ul class="lst_nominate_other">
          <li v-for="val in otherUser" :key="val">
            <a
              :href="
                $router.resolve({ name: 'NominateDtl', params: { id: val.id } })
                  .href
              "
            >
              <figure>
                <img :src="val.icon ? val.icon : Icon[0].img" alt="" />
              </figure>
              <p class="name">{{ val.nickname }}</p>
            </a>
          </li>
        </ul>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import LastRace from "@/components/LastRaceInfo.vue";
import Today from "@/components/Today.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  name: "NominateDtl",
  components: {
    LastRace,
    Today,
    Splide,
    SplideSlide,
  },
  data: function () {
    return {
      id: this.$route.params.id,
      options: {
        rewind: true,
        mediaQuery: "min",
        breakpoints: {
          769: {
            destroy: true,
          },
        },
        classes: {
          arrows: "splide__arrows mog-arrows",
          arrow: "splide__arrow mog-arrow",
          prev: "splide__arrow--prev mog-prev",
          next: "splide__arrow--next mog-next",
        },
        pagination: "splide__pagination mog-pagination",
        page: "splide__pagination__page mog-page",
        arrowPath: "M 0 0 L 25 25 L 0 50",
      },
      // Icon:"",
      // royalUser:"",
      // selectMotor:"",
      // pickupResult:"",
    };
  },
  beforeMount: function () {
    this.$store.dispatch("getRoyalUserDispatch", this.$route.params.id);
    this.$store.dispatch("getRoyalUserList");
  },
  mounted: function () {
    // this.$store.dispatch("getRoyalUser", this.id);
    this.$setInterval(() => {
      this.$store.dispatch("getRoyalUserDispatch", this.$route.params.id);
      this.$store.dispatch("getRoyalUserList");
    }, 15000);
    // this.selectMotor = this.$store.getters.getRoyalUserSelectMotor;
  },
  // mounted:function(){
  //   this.Icon = this.$store.getters.getIcon;
  //   this.royalUser = this.$store.getters.getRoyalUser;
  //   this.selectMotor = this.$store.getters.getRoyalUserSelectMotor;
  // },
  computed: {
    ...mapGetters({
      royalUser: "getRoyalUser",
      royalUserList: "getRoyalUserList",
      selectMotor: "getRoyalUserSelectMotor",
      Icon: "getIcon",
      motorImg: "getMotorImg",
    }),
    pickupResult: function () {
      if (this.selectMotor.length > 0) {
        return this.selectMotor.filter(function (item) {
          return item.pickup === true;
        });
      } else {
        return false;
      }
    },
    motorResult: function () {
      if (this.selectMotor.length > 0) {
        return this.selectMotor.filter(function (item) {
          return item.pickup === false;
        });
      } else {
        return false;
      }
    },
    otherUser: function () {
      let _this = this;
      if (this.royalUserList.length > 0) {
        return this.royalUserList.filter(function (item) {
          return item.id != _this.id;
        });
      } else {
        return false;
      }
    },
  },
  methods: {
    formatNewlines(text) {
      if (text === null || typeof text === "undefined") {
        return ""; // もしくは他のデフォルトの値を返す
      }
      return text.replace(/\n/g, "<br>");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
<style lang="css" src="@/assets/css/style_ranking.css" scoped></style>
<style>
.mog-arrow {
  background: transparent;
  opacity: 1;
}

.mog-prev {
  left: -2.4em;
}

.mog-next {
  right: -2.4em;
}

.splide__arrow svg {
  fill: none;
  stroke: #0a2961;
  stroke-width: 2px;
  height: 11vw;
  width: 11vw;
}

.box_lst .lst li.splide__slide {
  float: left;
}

.splide__pagination {
  display: none;
}

@media screen and (max-width: 768px) {
  .box_lst .lst li.splide__slide {
    float: none;
  }
}
</style>
